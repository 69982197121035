import React, { Component } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import moment from "moment";
import swal from "sweetalert";
import axios from "axios";
import Global from "../../../Global";
import { Fab, Tooltip } from "@material-ui/core";
import Sidebar from "./SideBarReserva";
import { FormControl, InputLabel, Select/*, MenuItem, Select*/ } from "@mui/material";
import { MenuItem } from "@material-ui/core"
import "moment/locale/es";

export default class TableReserva extends Component {
  state = {
    Reserva: [],
    Rol: 0,

    selectInmueble: "",
  };

  componentWillMount() {
    var varmoment;
    var fechaR;

    var data = {};
    var newReservas = [];

    for (var i = 0; i < this.props.m; i++) {
      data = this.props.Reservas[i];
      var hora = parseInt(data.horareserva);

      if (hora < 60) {
        hora = hora * 60;
      }

      if (hora > 0) {
        varmoment = moment(
          data.fechareserva + "T" + this.convertMinsToHrsMins(hora) + ":00"
        );
        fechaR = varmoment.format("LLLL");
      } else {
        varmoment = moment(data.fechareserva);
        fechaR = varmoment.format("LLLL");
      }
      var fechaS = moment(data.fechasolicitud).format("LLLL");
      var reserva = {
        aprobado: data.aprobado,
        direccion: data.direccion,
        fechareserva: fechaR,
        fechasolicitud: fechaS,
        idrecurso: data.idrecurso,
        idreserva: data.idreserva,
        imagenid: data.imagenid,
        nombrerecurso: data.nombrerecurso,
        nombres: data.nombres,
        reserva_cupo: parseInt(data.reserva_cupo) === 0 ? 'No aplica' : data.reserva_cupo,
        indice: i,
        fechaReservar: new Date(data.fechareserva + " 23:59:59"),
        horareserva: data.horareserva,
        Rol: parseInt(Global.GlobalUser.rol),
        estado: this.EstadoReserva(data.aprobado).text,
      };
      newReservas.push(reserva);
    }

    this.setState({
      Reserva: newReservas,
      Rol: parseInt(Global.GlobalUser.rol),
    });
  }

  convertMinsToHrsMins = (minutes) => {
    var h = Math.floor(minutes / 60);
    var m = minutes % 60;
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    return h + ":" + m;
  };

  EstadoReserva = (data) => {
    switch (parseInt(data)) {
      case 0:
        return { bg: "danger", text: "Cancelado" };
      case 1:
        return { bg: "danger", text: "Negado" };
      case 2:
        return { bg: "success", text: "Aprobado" };
      case 3:
        return { bg: "primary", text: "Estado" };
      case 4:
        return { bg: "warning", text: "Pendiente" };
      default:
        return { bg: "primary", text: "Estado" };
    }
  };

  Accion = (data, caso) => {
    var body = {};
    //aprobar
    if (caso === 1) {
      var mensaje =
        "La reserva de: \n" +
        data.nombres +
        ". \n Para el recurso \n" +
        data.nombrerecurso +
        "\n El " +
        data.fechareserva +
        "\n Fue APROBADA.";
      body = {
        ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
        codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
        caso: Buffer.from("1").toString("base64"),
        idreserva: Buffer.from("" + data.idreserva).toString("base64"),
        fechareserva: Buffer.from("" + data.fechaReservar).toString("base64"),
        horareserva: Buffer.from("" + data.horareserva).toString("base64"),
        idrecurso: Buffer.from("" + data.idrecurso).toString("base64"),
        iduser: Buffer.from("" + Global.GlobalUser.userid).toString("base64"),
      };

      axios
        .post(Global.Ruta.Url + "admin/resaccion/", body)
        .then((res2) => {
          if (res2.data.message === "") {
            swal({
              title: "Estupendo!!",
              text: mensaje,
              icon: "success",
              button: "Aceptar",
            }).then(() => {
              this.Actualiza(data.idreserva);
            });
          } else if (res2.data.message === "ERROR") {
            swal({
              title: "ALERTA!",
              text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
              icon: "error",
              button: "Aceptar",
            });
          } else {
            swal({
              title: "ALERTA!",
              text: res2.data.message,
              icon: "error",
              button: "Aceptar",
            });
          }
        })
        .catch((err) => { });
      //rechazar o cancelar
    } else if (caso === 2) {
      let mensaje = "";
      if (data.aprobado === 4) {
        mensaje =
          "Seguro quiere rechazar la reserva de: \n" +
          data.nombres +
          ". \n Para el recurso \n" +
          data.nombrerecurso +
          "? \n El " +
          data.fechareserva;
      } else {
        mensaje =
          "Seguro quiere cancelar la reserva de: \n" +
          data.nombres +
          ". \n Para el recurso \n" +
          data.nombrerecurso +
          "? \n El " +
          data.fechareserva;
      }
      swal({
        title: "ATENCIÓN!!",
        text: mensaje,
        icon: "warning",
        buttons: ["CANCELAR", "ACEPTAR"],
      }).then((result) => {
        if (result === true) {
          var caso;
          var Mmensaje = "";
          if (data.aprobado === 4) {
            caso = "2";
            Mmensaje = `La reserva de \n ${data.nombres}. \n Para el recurso \n ${data.nombrerecurso} \n Del ${data.fechareserva}\n Fue RECHAZADA.`;
          } else {
            caso = "3";
            Mmensaje = `La reserva de \n ${data.nombres}.  \n Para el recurso \n ${data.nombrerecurso} \n Del ${data.fechareserva}\n Fue CANCELADA.`;
          }
          body = {
            ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
            codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
            caso: Buffer.from("" + caso).toString("base64"),
            idreserva: Buffer.from("" + data.idreserva).toString("base64"),
            fechareserva: Buffer.from("").toString("base64"),
            horareserva: Buffer.from("").toString("base64"),
            idrecurso: Buffer.from("").toString("base64"),
            iduser: Buffer.from("" + Global.GlobalUser.userid).toString(
              "base64"
            ),
          };

          axios.post(Global.Ruta.Url + "admin/resaccion/", body).then((res2) => {
            if (res2.data.message === "") {
              swal({
                title: "Estupendo!!",
                text: Mmensaje,
                icon: "success",
                button: "Aceptar",
              }).then(() => {
                this.Actualiza(data.idreserva);
              });
            } else if (res2.data.message === "ERROR") {
              swal({
                title: "ALERTA!",
                text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                icon: "error",
                button: "Aceptar",
              });
            } else {
              swal({
                title: "ALERTA!",
                text: res2.data.message,
                icon: "error",
                button: "Aceptar",
              });
            }
          })
            .catch((err) => { });
        }
      });
    }
  };

  Actualiza = (idreserva) => {
    var tem = this.state.Reserva;
    var temi = this.BuscarIndexCambio(idreserva, tem);
    tem.splice(temi, 1);

    this.setState({ Reserva: tem });
  };

  BuscarIndexCambio = (PresId, temRows) => {
    // BUSCAR EN JSON FACIL -- SEARCH EASY ON OBJECT JSON
    const findIndexArray = (element) =>
      parseInt(element.idreserva) === parseInt(PresId);

    return temRows.findIndex(findIndexArray);
  };

  BuscarPorInmueble = (value) => {
    this.setState({ selectInmueble: value });
    this.props.findInm(value);
  };

  render() {
    let Estados = ["Cancelado", "Negado", "Aprobado", "Pendiente"];

    const Bar = () => {
      if (this.props.view) {
        return (
          <Sidebar
            Estados={Estados}
            Zonas={this.props.zonas}
            BorrarFiltro={this.props.reset}
            BuscarFiltro={this.props.find}
          />
        );
      } else {
        return <div></div>;
      }
    };

    return (
      <React.Fragment>
        <MaterialTable
          columns={[
            { title: "idrecurso", field: "idrecurso", hidden: true },
            { title: "idrecurso", field: "idrecurso", hidden: true },
            { title: "imagenid", field: "imagenid", hidden: true },
            { title: "Nombre", field: "nombres" },
            { title: "Dirección", field: "direccion" },
            { title: "Reserva", field: "nombrerecurso" },
            { title: "Fecha Solicitud", field: "fechasolicitud" },
            { title: "Fecha Reserva", field: "fechareserva" },
            { title: "Fecha Reserva", field: "fechareservar", hidden: true },
            { title: "Cupos", field: "reserva_cupo", hidden: this.props.view === false ? false : true },
            {
              title: "Aprobado",
              field: "aprobado",
              hidden: true,
            },
            {
              title: "Estado",
              field: "estado",
              render: (data) => (
                <span
                  className={`text-${this.EstadoReserva(data.aprobado).bg
                    } fs-7 m-0`}
                >
                  {data.estado}
                </span>
              ),
            },
          ]}
          data={this.state.Reserva}
          title={
            <div className="col" style={{ width: "36rem" }}>
              <div className="row align-items-center">
                <div className="col-auto">
                  <h5 className="m-0 text-nowrap">{this.props.title}</h5>
                </div>
                {this.props.view2 ? (
                  <div className="col">
                    <div className="row align-items-center">
                      <div className="col-6">
                        <FormControl fullWidth size="small" className="shadow">
                          <InputLabel id="demo-simple-select-label">
                            Inmueble
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={this.state.selectInmueble}
                            label="Inmueble"
                            onChange={(e) => {
                              this.BuscarPorInmueble(e.target.value);
                            }}
                          >
                            {this.props.Inmuebles.map((item, index) => (
                              <MenuItem key={index} value={item.inmuebleid}>
                                {item.direccion}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-auto">
                        {this.props.compAux !== undefined
                          ? this.props.compAux
                          : ""}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-auto">
                    {this.props.compAux !== undefined ? this.props.compAux : ""}
                  </div>
                )}
              </div>
            </div>
          }
          actions={[
            {
              onClickAprobar: (rowData) => {
                this.Accion(rowData, 1);
              },
              onClickCancerlar: (rowData) => {
                this.Accion(rowData, 2);
              },
            },
          ]}
          components={{
            Action: (props) =>
              props.data.fechaReservar >= new Date() && this.state.Rol <= 2 ? (
                <div className="d-flex ms-1" style={{ padding: "10px 3px" }}>
                  <Tooltip title="APROBAR" placement="left-start">
                    <Fab
                      aria-label="edit"
                      size="small"
                      onClick={(event) =>
                        props.action.onClickAprobar(props.data)
                      }
                      className={`me-1 text-white ${props.data.aprobado === 4
                        ? "bg-success"
                        : "bg-secondary"
                        }`}
                      disabled={props.data.aprobado === 4 ? false : true}
                    >
                      {props.data.aprobado === 4 ? (
                        <i className="fas fa-check" />
                      ) : (
                        ""
                      )}
                    </Fab>
                  </Tooltip>
                  <Tooltip
                    title={props.data.aprobado === 4 ? "RECHAZAR" : "CANCELAR"}
                    placement="top"
                  >
                    <Fab
                      color="primary"
                      aria-label="edit"
                      size="small"
                      className={`me-1 text-white ${props.data.aprobado > 1 ? "bg-danger" : "bg-secondary"
                        }`}
                      onClick={() => props.action.onClickCancerlar(props.data)}
                      disabled={props.data.aprobado > 1 ? false : true}
                    >
                      <i className="fas fa-times"></i>
                    </Fab>
                  </Tooltip>
                </div>
              ) : (
                props.data.fechaReservar >= new Date() && this.state.Rol === 4 ? (
                  props.data.aprobado !== 4 ? (
                    <div className="d-flex ms-1" style={{ padding: "10px 3px" }}>
                      <Tooltip
                        title={props.data.aprobado === 4 ? "RECHAZAR" : "CANCELAR"}
                        placement="top"
                      >
                        <Fab
                          color="primary"
                          aria-label="edit"
                          size="small"
                          className={`me-1 text-white ${props.data.aprobado > 1 ? "bg-danger" : "bg-secondary"
                            }`}
                          onClick={() => props.action.onClickCancerlar(props.data)}
                          disabled={props.data.aprobado > 1 ? false : true}
                        >
                          <i className="fas fa-times"></i>
                        </Fab>
                      </Tooltip>
                    </div>
                  ) : (
                    <div></div>
                  )
                ) : (
                  <div></div>
                )

              ),
            Toolbar: (props) => (
              <div className="col-12">
                <div className="row">
                  <MTableToolbar {...props} />
                </div>
                <Bar />
              </div>
            ),
          }}
          options={{
            pageSize: parseInt(this.props.m) < 20 ? parseInt(this.props.m) : 20,
            pageSizeOptions: [
              parseInt(this.props.m) < 20 ? parseInt(this.props.m) : 20,
              30,
              50,
            ],
            actionsColumnIndex: -1,
            rowStyle: (rowData) => {
              if (parseInt(rowData.tableData.id + 1) % 2 === 0) {
                return {
                  backgroundColor: "#eeeeee",
                  fontSize: '0.9rem',
                };
              }
              return {
                backgroundColor: "#ffffff",
                fontSize: '0.9rem',
              };
            },
          }}
          localization={{
            header: {
              actions: "Acciones",
            },
            body: {
              emptyDataSourceMessage: "No hay datos por mostrar.",
            },
            pagination: {
              labelRowsSelect: "registros",
              labelDisplayedRows: "{from}-{to} de {count}",
              firstTooltip: "Primera página",
              previousTooltip: "Página anterior",
              nextTooltip: "Próxima página",
              lastTooltip: "Última página",
            },
            toolbar: {
              searchTooltip: "Buscar",
              searchPlaceholder: "Buscar",
            },
          }}
        />
      </React.Fragment>
    );
  }
}
