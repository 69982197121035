import React, { Component } from "react";
import axios from "axios";
import Global from "../../../Global";
import { CookieSesion } from "../../Helpers";
import swal from "sweetalert";
import { Redirect } from "react-router-dom";
import { TextField } from "@material-ui/core";
//--------IMPORT COMPONENTS--------
//import Sidebar from "./SideBarReserva";
//import CartaReserva from "./CartaReserva";
import { Modal, Tab, Tabs } from "react-bootstrap";
import ModalReserva from "./modal/ModalReserva";
import TableReserva from "./TableReserva";
import HeadTitle from "../../principal/HeadTitle";
import Loader from "../../principal/Loader";
import { removeDiacritics } from "../../Helpers"

//let switcher = "";
class ContainerReservas extends Component {
  state = {
    Zonas: [],
    itemList: [],
    Data: false,
    Reservas: [],
    ParaBusqueda: [],
    Busqueda: "",
    //redirect
    redirect_home: false,
    redirect_login: 0,

    // tabla
    cols: [],
    tableData: [],

    Roles: [1, 2, 4, 5],

    tab: "0",
    dataTable: false,
    nombreTab: 'Reservas'
  };

  componentWillMount() {
    var login = CookieSesion();
    var userid = Global.GlobalUser.userid;

    if (parseInt(login) > 0) {
      if (parseInt(userid) > 0) {
        if (
          this.state.Roles.includes(Global.GlobalUser.rol)
        ) {
          axios.get(Global.Ruta.Url + "admin/nomrecursos/" + Buffer.from(Global.Ph.Codigo).toString("base64") + "/" + Buffer.from(Global.Ruta.Ipin).toString("base64")).then((res) => {
            if (res.data.message === "") {
              this.setState({
                Zonas: res.data.zonas,
              });
              if (this.props.caso === undefined) {
                this.resetFiltro(0);
              } else {
                this.setState({ tab: "1" })
                this.resetFiltro(1);
              }

            } else if (res.data.message === "ERROR") {
              swal({
                title: "ALERTA!",
                text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                icon: "error",
                button: "Aceptar",
              }).then(() => {
                this.setState({ redirect_home: true });
              });
            } else {
              swal({
                title: "ALERTA!",
                text: res.data.message,
                icon: "error",
                button: "Aceptar",
              }).then(() => {
                this.setState({ redirect_home: true });
              });
            }
          }).catch((err) => {
            this.setState({
              redirect_home: true,
            });
          });
        } else {
          this.setState({ redirect_home: true });
        }
      } else {
        this.setState({ redirect_login: 1 });
      }
    } else {
      this.setState({ redirect_login: 1 });
    }
  }

  resetFiltro = (tipo) => {
    var url = {};

    switch (parseInt(tipo)) {
      case 0:
        url = {
          method: "get",
          url: `${Global.Ruta.Url}admin/respendientes/${Buffer.from(
            Global.Ph.Codigo
          ).toString("base64")}/${Buffer.from(Global.Ruta.Ipin).toString(
            "base64"
          )}`,
        };
        break;
      case 1:
        url = {
          method: "post",
          url: `${Global.Ruta.Url}admin/reservashoy/`,
          data: {
            ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
            codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
          },
        };
        break;
      case 2:
        this.setState({
          dataTable: false,
        });
        setTimeout(() => {
          this.setState({
            Reservas: [],
            dataTable: true,
          });
        }, 100);

        break;
      case 3:
        var body = {
          ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
          codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
        };

        axios
          .post(Global.Ruta.Url + "admin/selectinmueble/", body)
          .then((res) => {
            if (res.data.message === "") {
              this.setState({
                Data: true,
                dataTable: true,
                Reservas: [],
                itemList: res.data.data,
              });
            }
          })
          .catch((err) => {
            swal({
              title: "ATENCIÓN!",
              text: "Vaya algo salio mal, por favor vuelva a intentarlo...",
              icon: "error",
              button: "Aceptar",
            }).then(() => { });
          });

        break;
      default:
        break;
    }

    if (parseInt(tipo) !== 2 && parseInt(tipo) !== 3) {
      axios(url)
        .then((res2) => {
          if (res2.data.message === "") {

            this.setState({
              Reservas: res2.data.reservas,
              ParaBusqueda: res2.data.reservas,
              Data: true,
              dataTable: true,
            });
          } else if (res2.data.message === "ERROR") {
            swal({
              title: "ALERTA!",
              text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
              icon: "error",
              button: "Aceptar",
            }).then(() => {
              this.setState({ redirect_home: true });
            });
          } else {
            swal({
              title: "ALERTA!",
              text: res2.data.message,
              icon: "error",
              button: "Aceptar",
            }).then(() => {
              this.setState({ redirect_home: true });
            });
          }
        })
        .catch((err) => {
          console.log(err.message);
          this.setState({
            redirect_home: true,
          });
        });
    }
  };

  findFiltro = (filtro) => {
    this.setState({
      Reservas: [],
      dataTable: false,
    });
    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      caso: Buffer.from(filtro.caso).toString("base64"),
      idrecurso: Buffer.from(filtro.idrecurso).toString("base64"),
      estado: Buffer.from(filtro.estado).toString("base64"),
      fechai: Buffer.from(filtro.fechai).toString("base64"),
      fechaf: Buffer.from(filtro.fechaf).toString("base64"),
    };
    axios
      .post(Global.Ruta.Url + "admin/resfiltro/", body)
      .then((res2) => {
        if (res2.data.message === "") {
          this.setState({
            Reservas: res2.data.reservas,
            dataTable: true,
          });
        } else if (res2.data.message === "ERROR") {
          swal({
            title: "ALERTA!",
            text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
            icon: "error",
            button: "Aceptar",
          }).then(() => {
            this.setState({ redirect_home: true });
          });
        } else {
          swal({
            title: "ALERTA!",
            text: res2.data.message,
            icon: "error",
            button: "Aceptar",
          }).then(() => {
            this.setState({ redirect_home: true });
          });
        }
      })
      .catch((err) => {
        this.setState({
          redirect_home: true,
        });
      });
  };

  EliminarR = (indice) => {
    this.setState({
      Data: false,
    });
    this.resetFiltro(0);
  };

  findInmueble = (value) => {
    this.setState({
      Reservas: [],
      dataTable: false,
    });
    var body = {
      ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
      codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      inmuebleid: Buffer.from("" + value).toString("base64"),
    };
    axios
      .post(Global.Ruta.Url + "admin/reservasidinmueble/", body)
      .then((res2) => {
        if (res2.data.message === "") {
          this.setState({
            Reservas: res2.data.reservas,
            dataTable: true,
          });
        } else if (res2.data.message === "ERROR") {
          swal({
            title: "ALERTA!",
            text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
            icon: "error",
            button: "Aceptar",
          }).then(() => {
            this.setState({ redirect_home: true });
          });
        } else {
          swal({
            title: "ALERTA!",
            text: res2.data.message,
            icon: "error",
            button: "Aceptar",
          }).then(() => {
            this.setState({ redirect_home: true });
          });
        }
      })
      .catch((err) => {
        this.setState({
          redirect_home: true,
        });
      });
  };

  SelectTap = (opc) => {
    this.setState({
      tab: opc,
      dataTable: false,
    });

    switch (opc) {
      case '0':
        this.setState({
          nombreTab: 'Pendiente'
        });
        break;
      case '1':
        this.setState({
          nombreTab: 'Reserva Hoy'
        });
        break;
      case '2':
        this.setState({
          nombreTab: 'Historia'
        });
        break;
      case '3':
        this.setState({
          nombreTab: 'Reservas Por Inmueble'
        });
        break;
      default:
        this.setState({
          nombreTab: 'Reservas'
        });
        break;
    }

    this.resetFiltro(parseInt(opc));
  };

  convertMinsToHrsMins = (minutes) => {
    var h = Math.floor(minutes / 60);
    var m = minutes % 60;
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    return h + ":" + m;
  };

  EstadoReserva = (data) => {
    switch (parseInt(data)) {
      case 0:
        return { bg: "danger", text: "Cancelado" };
      case 1:
        return { bg: "danger", text: "Negado" };
      case 2:
        return { bg: "success", text: "Aprobado" };
      case 3:
        return { bg: "primary", text: "Estado" };
      case 4:
        return { bg: "warning", text: "Pendiente" };
      default:
        return { bg: "primary", text: "Estado" };
    }
  };

  handleChange = (paramprop) => (event) => {
    this.setState((state) => ({
      ...state,
      [paramprop]: event.target.value
    }));
    this.setState({
      Reservas: this.filterItems(event.target.value, this.state.ParaBusqueda)
    })
  }

  filterItems = (query, temRows) => {
    return temRows.filter(function (data) {
      return (
        removeDiacritics(data.nombres).toLowerCase().indexOf(removeDiacritics(query).toLowerCase()) > -1 ||
        removeDiacritics(data.direccion).toLowerCase().indexOf(removeDiacritics(query).toLowerCase()) > -1 ||
        removeDiacritics(data.nombrerecurso).toLowerCase().indexOf(removeDiacritics(query).toLowerCase()) > -1);
    })
  }

  cancelarReserva = (data, e) => {
    e.preventDefault();
    let mensaje =
      "Seguro quiere cancelar la reserva de: \n" +
      data.nombres +
      ". \n Para el recurso \n" +
      data.nombrerecurso +
      "? \n El " +
      data.fechareserva;
    swal({
      title: "ATENCIÓN!!",
      text: mensaje,
      icon: "warning",
      buttons: ["CANCELAR", "ACEPTAR"],
    }).then((result) => {
      if (result === true) {
        var Mmensaje = `La reserva de \n ${data.nombres}.  \n Para el recurso \n ${data.nombrerecurso} \n Del ${data.fechareserva}\n Fue CANCELADA.`;

        var body = {
          ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
          codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
          caso: Buffer.from("3").toString("base64"),
          idreserva: Buffer.from("" + data.idreserva).toString("base64"),
          fechareserva: Buffer.from("").toString("base64"),
          horareserva: Buffer.from("").toString("base64"),
          idrecurso: Buffer.from("").toString("base64"),
          iduser: Buffer.from("" + Global.GlobalUser.userid).toString("base64"),
        };

        axios.post(Global.Ruta.Url + "admin/resaccion/", body).then((res2) => {
          if (res2.data.message === "") {
            swal({
              title: "Estupendo!!",
              text: Mmensaje,
              icon: "success",
              button: "Aceptar",
              timer: 1000
            }).then(() => {
              this.setState({ modal: false });
              var tem = this.state.ParaBusqueda;
              var index = this.IndexCambio(data.idreserva, tem);

              tem.splice(index, 1);
              this.setState({
                Reservas: tem,
                Busqueda: ""
              });
            });
          } else if (res2.data.message === "ERROR") {
            swal({
              title: "ALERTA!",
              text: "Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
              icon: "error",
              button: "Aceptar",
              timer: 1000
            });
          } else {
            swal({
              title: "ALERTA!",
              text: res2.data.message,
              icon: "error",
              button: "Aceptar",
              timer: 1000
            });
          }
        }).catch((err) => { });
      }
    });
  }

  IndexCambio = (Id, temRows) => {
    // BUSCAR EN JSON FACIL -- SEARCH EASY ON OBJECT JSON
    const findIndexArray = (element) =>
      parseInt(element.idreserva) === parseInt(Id);

    return temRows.findIndex(findIndexArray);
  };

  render() {

    let Estados = ["Cancelado", "Negado", "Aprobado", "Pendiente"];

    const { tab, redirect_home, redirect_login, Data, modalShow1 } = this.state

    if (redirect_home === true) {
      return <Redirect to={Global.Ruta.Raiz + "/home-admin"} />;
    }

    if (redirect_login === 1) {
      return <Redirect to={Global.Ruta.Raiz + "/"} />;
    }

    if (Data === true && this.props.caso === undefined) {
      return (
        <div className="container-fluid bg-light h-100 overflow-auto">
          <Modal
            show={modalShow1}
            backdrop="static"
            centered
            size="lg"
            onHide={() => this.setState({ modalShow1: false })}
          >
            <ModalReserva
              cerrar={() => {
                this.setState({ modalShow1: false, dataTable: false });
                this.resetFiltro(0);
              }}
            />
          </Modal>

          <div className="row">
            {parseInt(Global.GlobalUser.rol) !== 5 && <div className="col-12 pt-3 pb-4">
              <HeadTitle
                titulo='Reservas'
                classNameOwn="row px-3"
                indicadores={[]}
                actionButton={
                  <button
                    className="btn btn-success text-nowrap btn-sm"
                    onClick={() => this.setState({ modalShow1: true })}
                  >
                    <i className="fas fa-plus"></i> Nueva Reserva
                  </button>
                }
              />
            </div>}

            <div className="col-12 pb-5 pt-3">
              <Tabs
                activeKey={tab}
                onSelect={this.SelectTap}
                id="tabs-reservas"
                className=" rounded-top"
                justify
              >
                <Tab eventKey="0" title="Pendientes" tabClassName={`${tab === '0' && 'bg-primary link-light'} border-primary border-bottom-0 h-100`}>
                  <div className="row h-100">
                    <div className="col-12 h-100">
                      {this.state.dataTable ? (
                        <TableReserva
                          Reservas={this.state.Reservas}
                          title={"Reservas pendientes por aprobación"}
                          m={this.state.Reservas.length}
                          view={false}
                        />
                      ) : (
                        <div
                          className="d-flex justify-content-center flex-column align-items-center text-center"
                          style={{ height: "400px" }}
                        >
                          <div
                            className="spinner-grow text-info"
                            role="status"
                            style={{ width: "5rem", height: "5rem" }}
                          />
                          <span className="my-2 ">Espere un momento...</span>
                        </div>
                      )}
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="1" title="Reservas de Hoy" tabClassName={`${tab === '1' && 'bg-primary link-light'} border-primary border-bottom-0 h-100`}>
                  <div className="row h-100">
                    <div className="col-12 h-100">
                      {this.state.dataTable ? (
                        <TableReserva
                          Reservas={this.state.Reservas}
                          title={"Reservas para el día de hoy"}
                          m={this.state.Reservas.length}
                          view={false}
                        />
                      ) : (
                        <div
                          className="d-flex justify-content-center flex-column align-items-center text-center"
                          style={{ height: "400px" }}
                        >
                          <div
                            className="spinner-grow text-info"
                            role="status"
                            style={{ width: "5rem", height: "5rem" }}
                          />
                          <span className="my-2 ">Espere un momento...</span>
                        </div>
                      )}
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="2" title="Historial" tabClassName={`${tab === '2' && 'bg-primary link-light'} border-primary border-bottom-0 h-100`}>
                  <div className="row h-100">
                    <div className="col-12 h-100">
                      {this.state.dataTable ? (
                        <TableReserva
                          Reservas={this.state.Reservas}
                          title={"Historial"}
                          m={this.state.Reservas.length}
                          view={true}
                          estado={Estados}
                          zonas={this.state.Zonas}
                          z={this.state.Zonas.length}
                          reset={this.resetFiltro}
                          find={this.findFiltro}
                        />
                      ) : (
                        <div
                          className="d-flex justify-content-center flex-column align-items-center text-center"
                          style={{ height: "400px" }}
                        >
                          <div
                            className="spinner-grow text-info"
                            role="status"
                            style={{ width: "5rem", height: "5rem" }}
                          />
                          <span className="my-2 ">Espere un momento...</span>
                        </div>
                      )}
                    </div>
                  </div>
                </Tab>
                <Tab
                  eventKey="3"
                  title="Reservas por Inmueble"
                  tabClassName={`${tab === '3' && 'bg-primary link-light'} border-primary border-bottom-0 h-100`}
                >
                  <div className="row h-100">
                    <div className="col-12 h-100">
                      {this.state.dataTable ? (
                        <TableReserva
                          Reservas={this.state.Reservas}
                          m={this.state.Reservas.length}
                          view2={true}
                          Inmuebles={this.state.itemList}
                          title={"Reservas por Inmueble"}
                          findInm={this.findInmueble}
                        />
                      ) : (
                        <div
                          className="d-flex justify-content-center flex-column align-items-center text-center"
                          style={{ height: "400px" }}
                        >
                          <div
                            className="spinner-grow text-info"
                            role="status"
                            style={{ width: "5rem", height: "5rem" }}
                          />
                          <span className="my-2 ">Espere un momento...</span>
                        </div>
                      )}
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      );
    }

    if (Data === true && this.props.caso !== undefined) {

      return (
        <React.Fragment>
          <Modal
            show={modalShow1}
            backdrop="static"
            centered
            size="lg"
            onHide={() => this.setState({ modalShow1: false })}
          >
            <ModalReserva
              cerrar={() => {
                this.setState({ modalShow1: false, dataTable: false });
                this.resetFiltro(0);
              }}
            />
          </Modal>

          <div className="mb-2 row" style={{ height: "60px" }}>
            <div className="col">
              <TextField
                autoFocus
                size="small"
                fullWidth
                margin="normal"
                placeholder="Buscar Reserva"
                variant="outlined"
                defaultValue={this.state.Busqueda}
                value={this.state.Busqueda}
                onChange={this.handleChange("Busqueda")}
              />
            </div>
            <div className="col-auto d-flex align-items-center justify-content-center">
              <button className="btn btn-success rounded-circle btn-sm" onClick={() => this.setState({ modalShow1: true })}>
                <i className="fa-solid fa-plus" />
              </button>
            </div>
          </div>

          <div className="w-100 h-100 position-relative align-items-start overflow-x-hidden custom-scroll">
            <div className=" rounded bg-light mx-2 mb-3 d-flex flex-column position-relative">
              <div className="h-100 position-relative align-items-start" style={{ maxHeight: "89%" }}>
                <div className="h-100" style={{ padding: "0px 0px 0px 5px" }}>
                  {this.state.Reservas.map((data, index) => (

                    <div className="row border  border-secondary rounded bg-white my-2" key={index} style={{ marginInline: "0px", width: "95%" }} >
                      <div className="col" style={{ minWidth: "cal(100%-45px)" }}>
                        <span style={{ fontSize: "12px", fontWeight: "bold" }}>{data.nombres}</span>
                        <br />
                        <span style={{ fontSize: "12px" }}>{data.direccion}</span>
                        <br />
                        <span style={{ fontSize: "12px" }}>{data.nombrerecurso}</span> -
                        <span className={`text-${this.EstadoReserva(data.aprobado).bg}`} style={{ fontSize: "12px" }}>{" " + this.EstadoReserva(data.aprobado).text.toUpperCase()} </span>
                        <br />
                        <span style={{ fontSize: "12px" }}>{data.fechareserva + " " + this.convertMinsToHrsMins(data.horareserva)}</span>
                      </div>
                      <div className="col-auto d-flex align-items-center justify-content-center">
                        <button className="btn btn-danger rounded-circle btn-sm" onClick={(e) => this.cancelarReserva(data, e)}>
                          <i className="fa-solid fa-xmark" />
                        </button>
                      </div>
                      {/* <div className=" d-flex align-items-center justify-content-center" style={{ maxWidth: "45px" }}>
                          <button className="btn btn-primary rounded-circle btn-sm" onClick={() => console.log(data)}>
                            <i class="fa-solid fa-check" />
                          </button>
                        </div> */}
                    </div>


                  ))
                  }
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>

      );
    }

    return (
      // <div className="d-flex justify-content-center text-info donita">
      //   <div className="spinner-border" role="status">
      //     <span className="sr-only">Loading...</span>
      //   </div>
      // </div>
      <Loader princial={true} uno={"Reservas"} dos={"Datos"} tres={"Historial"} />
    );
  }
}

export default ContainerReservas;
