import React, {Component} from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Card,
} from "@material-ui/core";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import {Modal} from "react-bootstrap";
import textFloow from "../../../assets/css/textFloow.module.css";

//conecxion, cockies y alertas
import axios from "axios";
import Global from "../../../Global";
import swal from "sweetalert";
import {CookieSesion} from "../../Helpers";
import {Redirect} from "react-router-dom";

//componentes
import TablaContactosMsn from "./TablaContactosMsn";
import TablaGruposMsn from "./TablaGruposMsn";
import TablaSelectGruposMsn from "./TablaSelectGruposMsn";
import TablaListSubApp from "./TablaListSubApp";
import TablaListHmsn from "./TablaListHmsn";
import ListaRespAut from "./ListaRespAut";
import TablaListSubSapp from "./TablaListSubSapp";
import TablaHistorialPort from "./Modal/TablaHistorialPort";
import HeadTitle from "../../principal/HeadTitle";

class SistemaMsn extends Component {
  state = {
    password: "",
    titulo: "",
    contenido: "",
    TipoEnvio: 1,
    showPassword: false,
    data: false,
    NombreDesti: Global.GlobalUser.rol === 4 ? "TODOS LOS RESIDENTES" : "TODOS",

    /* LOS REDIRECTS */
    redirect_login: 0,
    redirect_home: false,

    /* ESTADO DE LOS POPUS */
    modalShow: false,
    modalShowG: false,
    modalShowEditG: false,
    modalHistoriMsn: false,
    modalSubApp: false,
    modalRespAut: false,
    modalPasswrd: false,
    modalSubSapp: false,
    modalHistoriPort: false,

    /* CASO PARA LISTAR RESIDENTES O PROPIETARIOS, EL TITULO, LOS DATOS RETORNADOS */
    caso: 0,
    tituloTabla: "",
    IdInmuebleSelect: [],
    IdSuscSelect: [],

    // ESTADOS DEL CHECKED
    checkbox:
      Global.GlobalUser.rol === 4
        ? [false, false, true, false, false, false]
        : [true, false, false, false, false, false],

    // MENSAJE AUTOMATICO
    msnAutomatico: "Cuerpo del Mensaje",
  };

  componentWillMount() {
    var login = CookieSesion();
    var userid = Global.GlobalUser.userid;
    var userRol = Global.GlobalUser.rol;

    if (parseInt(login) > 0) {
      if (parseInt(userid) > 0) {
        if (
          parseInt(userRol) === 1 ||
          parseInt(userRol) === 2 ||
          parseInt(userRol) === 4
        ) {
          this.setState({
            data: true,
            TipoEnvio: parseInt(userRol) !== 4 ? 1 : 3,
          });
        } else {
          this.setState({redirect_home: true});
        }
      } else {
        this.setState({redirect_login: 1});
      }
    } else {
      this.setState({redirect_login: 1});
    }
  }

  /* ACTUALIZAR EL NOMBRE, PASSWORD, TITULO, CONTENIDO */
  handleChange = (prop) => (event) => {
    this.setState((state) => ({
      ...state,
      [prop]: event.target.value,
    }));
  };

  EstadoVerClave = () => {
    this.setState((state) => ({
      showPassword: !state.showPassword,
    }));
  };

  /* CERRAR LOS MODALES */
  CloseModal = () => {
    this.setState({modalShow: false});
  };
  CloseModalG = () => {
    this.setState({modalShowG: false});
  };
  CloseModalEditG = () => {
    this.setState({modalShowEditG: false});
  };
  CloseModalHstrMsn = () => {
    this.setState({modalHistoriMsn: false});
  };
  ClosemodalSubApp = () => {
    this.setState({modalSubApp: false});
  };

  /* EL CHECKED */
  checked = (event, indice) => {
    var tem = [];
    for (var i = 0; i < this.state.checkbox.length; i++) {
      if (i === indice) {
        tem.push(event.target.checked);
      } else {
        tem.push(false);
      }
    }
    this.setState({
      checkbox: tem,
    });
    switch (indice) {
      case 0:
        this.setState({
          NombreDesti: "TODOS",
        });
        break;
      case 1:
        this.setState({
          NombreDesti: "SOLO PROPIETARIOS",
        });
        break;
      case 2:
        this.setState({
          NombreDesti:
            Global.GlobalUser.rol === 4
              ? "TODOS LOS RESIDENTES"
              : "SOLO RESIDENTES",
        });
        break;
      default:
        this.setState({
          NombreDesti: "NINGUNO",
        });
        break;
    }
    if (indice === 3 && this.state.checkbox[indice] === false) {
      this.setState({modalShow: true, tituloTabla: "Propietario", caso: 2});
    }
    if (indice === 4 && this.state.checkbox[indice] === false) {
      this.setState({modalShow: true, tituloTabla: "Residente", caso: 1});
    }
    if (indice === 5 && this.state.checkbox[indice] === false) {
      this.setState({modalShowG: true, tituloTabla: "Grupos"});
    }
    this.setState({
      TipoEnvio: parseInt(indice) + 1,
    });
  };

  //CERRAR POPUPS SI ALGO SALIO MAL
  cerrarModal = () => {
    swal({
      title: "ALERTA!",
      text: "Vaya algo ha salido mal, por favor vuelva a intentarlo.",
      icon: "error",
      button: "Aceptar",
    }).then(() => {
      this.setState({modalShow: false});
    });
  };
  cerrarModelG = () => {
    swal({
      title: "ALERTA!",
      text: "Vaya algo ha salido mal, por favor vuelva a intentarlo.",
      icon: "error",
      button: "Aceptar",
    }).then(() => {
      this.setState({modalShowG: false});
    });
  };
  cerrarModelEditG = () => {
    swal({
      title: "ALERTA!",
      text: "Vaya algo ha salido mal, por favor vuelva a intentarlo.",
      icon: "error",
      button: "Aceptar",
    }).then(() => {
      this.setState({CloseModalEditG: false});
    });
  };
  cerrarModelHmsn = () => {
    swal({
      title: "ALERTA!",
      text: "Vaya algo ha salido mal, por favor vuelva a intentarlo.",
      icon: "error",
      button: "Aceptar",
    }).then(() => {
      this.setState({modalHistoriMsn: false});
    });
  };
  cerrarModalSubApp = () => {
    swal({
      title: "ALERTA!",
      text: "Vaya algo ha salido mal, por favor vuelva a intentarlo.",
      icon: "error",
      button: "Aceptar",
    }).then(() => {
      this.setState({modalSubApp: false});
    });
  };
  cerrarModalSubSinApp = () => {
    swal({
      title: "ALERTA!",
      text: "Vaya algo ha salido mal, por favor vuelva a intentarlo.",
      icon: "error",
      button: "Aceptar",
    }).then(() => {
      this.setState({modalSubSapp: false});
    });
  };

  /* CONSTRUIR NOMBRE DESTINATARIO */
  nomDestinatario = (Nombres) => {
    var ReturNombre = "";
    for (var i = 0; i < Nombres.length; i++) {
      if (i === Nombres.length - 1) {
        ReturNombre += Nombres[i];
      } else {
        ReturNombre += Nombres[i] + " - ";
      }
    }
    return ReturNombre;
  };

  /* GUARDAR ALGUN DATO DE LOS POPUPS PARA MANDAR EL MENSAJE */
  guardarDataModal = (Data) => {
    var TemInmuebleId = [];
    var TemSuscId = [];
    var Nombres = [];
    for (var i of Data) {
      TemInmuebleId.push(i.inmuebleid);
      TemSuscId.push(i.suscriptorid);
      Nombres.push(i.nombres);
    }

    var Nombre = this.nomDestinatario(Nombres);

    this.setState({
      modalShow: false,
      IdInmuebleSelect: TemInmuebleId,
      NombreDesti: Nombre,
      IdSuscSelect: TemSuscId,
    });
  };

  guardarModalSelectG = (Data, Nombres) => {
    var Nombre = "Grupo: " + this.nomDestinatario(Nombres);
    this.setState({modalShowG: false, IdSuscSelect: Data, NombreDesti: Nombre});
  };

  EnviarMsn = () => {
    this.setState({data: false});
    if (this.state.titulo === "") {
      swal({
        title: "ALERTA!",
        text: "Debe colocar un titulo al mensaje.",
        icon: "error",
        button: "Aceptar",
      }).then(() => {
        this.setState({data: true});
      });
    }
    if (this.state.contenido === "") {
      swal({
        title: "ALERTA!",
        text: "Debe colocar texto en el mensaje a enviar.",
        icon: "error",
        button: "Aceptar",
      }).then(() => {
        this.setState({data: true});
      });
    }
    if (this.state.password === "") {
      swal({
        title: "ALERTA!",
        text: "Debe colocar la contraseña.",
        icon: "error",
        button: "Aceptar",
      }).then(() => {
        this.setState({data: true});
      });
    }
    if (
      this.state.titulo !== "" &&
      this.state.contenido !== "" &&
      this.state.password !== ""
    ) {
      var body = {
        password: Buffer.from(this.state.password).toString("base64"),
        userid: Buffer.from("" + Global.GlobalUser.userid).toString("base64"),
        codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      };

      axios
        .post(Global.Ruta.Url + "mensajeria/pwdmensajeria/", body)
        .then((resp) => {
          if (resp.data.message === "") {
            var body = {
              ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
              codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
              userid: Buffer.from("" + Global.GlobalUser.userid).toString(
                "base64"
              ),
              caso: Buffer.from("" + this.state.TipoEnvio).toString("base64"),
              suscid: this.state.IdSuscSelect,
              inmuebleid: this.state.IdInmuebleSelect,
              titulo: Buffer.from(this.state.titulo).toString("base64"),
              mensaje: Buffer.from(this.state.contenido).toString("base64"),
              phname: Buffer.from(Global.Ph.Nombre).toString("base64"),
            };
            axios
              .post(Global.Ruta.Url + "mensajeria/sendmensaje/", body)
              .then((resp1) => {
                if (resp1.data.message === "") {
                  swal({
                    title: "MENSAJE ENVIADO",
                    text: "Desea enviar otro mensaje?",
                    icon: "success",
                    buttons: ["NO", "SI"],
                  }).then((result) => {
                    if (result) {
                      this.setState({
                        password: "",
                        titulo: "",
                        contenido: "",
                        TipoEnvio: 1,
                        modalPasswrd: false,
                        data: true,
                        NombreDesti: "",
                        caso: 0,
                        tituloTabla: "",
                        IdInmuebleSelect: [],
                        checkbox: [true, false, false, false, false, false],
                      });
                    } else {
                      this.setState({
                        redirect_home: true,
                      });
                    }
                  });
                } else {
                  swal({
                    title: "ALERTA!",
                    text: resp1.data.message,
                    icon: "error",
                    button: "Aceptar",
                  }).then(() => {
                    this.setState({data: true, modalPasswrd: false});
                  });
                }
              })
              .catch((err) => {
                swal({
                  title: "ALERTA!",
                  text: "51010 - Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                  icon: "error",
                  button: "Aceptar",
                }).then(() => {
                  this.setState({data: true, modalPasswrd: false});
                });
              });
          } else {
            swal({
              title: "ALERTA!",
              text: "Contraseña incorrecta.",
              icon: "error",
              button: "Aceptar",
            }).then(() => {
              this.setState({data: true, modalPasswrd: false});
            });
          }
        })
        .catch((err) => {
          swal({
            title: "ALERTA!",
            text: "61010 - Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
            icon: "error",
            button: "Aceptar",
          }).then(() => {
            this.setState({data: true, modalPasswrd: false});
          });
        });
    }
  };

  EnviarMsnPorteria = () => {
    this.setState({data: false});

    if (this.state.titulo === "") {
      swal({
        title: "ALERTA!",
        text: "Debe colocar un titulo al mensaje.",
        icon: "error",
        button: "Aceptar",
      }).then(() => {
        this.setState({data: true});
      });
    }
    if (this.state.contenido === "") {
      swal({
        title: "ALERTA!",
        text: "Debe colocar texto en el mensaje a enviar.",
        icon: "error",
        button: "Aceptar",
      }).then(() => {
        this.setState({data: true});
      });
    }
    if (this.state.password === "") {
      swal({
        title: "ALERTA!",
        text: "Debe colocar la contraseña.",
        icon: "error",
        button: "Aceptar",
      }).then(() => {
        this.setState({data: true});
      });
    }
    if (
      this.state.titulo !== "" &&
      this.state.contenido !== "" &&
      this.state.password !== ""
    ) {
      var body = {
        password: Buffer.from(this.state.password).toString("base64"),
        userid: Buffer.from("" + Global.GlobalUser.userid).toString("base64"),
        codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
      };
      axios
        .post(Global.Ruta.Url + "mensajeria/pwdmensajeria/", body)
        .then((resp) => {
          if (resp.data.message === "") {
            var body = {
              ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
              codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
              asunto: Buffer.from(this.state.titulo).toString("base64"),
              mensaje: Buffer.from(this.state.contenido).toString("base64"),
              idportero: Buffer.from("" + Global.GlobalUser.userid).toString(
                "base64"
              ),
              suscid: this.state.IdSuscSelect,
              inmuebleid: this.state.IdInmuebleSelect,
              caso: Buffer.from("" + this.state.TipoEnvio).toString("base64"),
            };

            axios
              .post(Global.Ruta.Url + "porteria/enviarmensaje/", body)
              .then((resp1) => {
                if (resp1.data.message === "") {
                  swal({
                    title: "MENSAJE ENVIADO",
                    text: "Desea enviar otro mensaje?",
                    icon: "success",
                    buttons: ["NO", "SI"],
                  }).then((result) => {
                    if (result) {
                      this.setState({
                        password: "",
                        titulo: "",
                        contenido: "",
                        TipoEnvio: 3,
                        showPassword: false,
                        data: true,
                        NombreDesti: "",
                        caso: 0,
                        tituloTabla: "",
                        IdInmuebleSelect: [],
                        checkbox: [true, false, false, false, false, false],
                      });
                    } else {
                      this.setState({
                        redirect_home: true,
                      });
                    }
                  });
                } else {
                  swal({
                    title: "ALERTA!",
                    text: resp1.data.message,
                    icon: "error",
                    button: "Aceptar",
                  }).then(() => {
                    this.setState({data: true});
                  });
                }
              })
              .catch((err) => {
                swal({
                  title: "ALERTA!",
                  text: "51010 - Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
                  icon: "error",
                  button: "Aceptar",
                }).then(() => {
                  this.setState({data: true});
                });
              });
          } else {
            swal({
              title: "ALERTA!",
              text: "Contraseña incorrecta.",
              icon: "error",
              button: "Aceptar",
            }).then(() => {
              this.setState({data: true});
            });
          }
        })
        .catch((err) => {
          swal({
            title: "ALERTA!",
            text: "61010 - Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
            icon: "error",
            button: "Aceptar",
          }).then(() => {
            this.setState({data: true});
          });
        });
    }
  };

  MensajesAutm = (Caso, Mensaje) => {
    this.setState({modalRespAut: false, data: false});
    swal({
      title: "ENVIAR MENSAJE",
      text: Mensaje,
      content: {
        element: "input",
        attributes: {
          placeholder: "Ingrese su Contraseña",
          type: "password",
        },
      },
      icon: "warning",
      buttons: ["Cancelar", "Enviar"],
    }).then((result) => {
      if (result) {
        var body = {
          password: Buffer.from(this.state.password).toString("base64"),
          userid: Buffer.from("" + Global.GlobalUser.userid).toString("base64"),
          codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
        };
        axios
          .post(Global.Ruta.Url + "mensajeria/pwdmensajeria/", body)
          .then((resp) => {
            if (resp.data.message === "") {
              var body = {
                ipin: Buffer.from(Global.Ruta.Ipin).toString("base64"),
                codigoph: Buffer.from(Global.Ph.Codigo).toString("base64"),
                opcion: Buffer.from("" + Caso).toString("base64"),
                idportero: Buffer.from("" + Global.GlobalUser.userid).toString(
                  "base64"
                ),
                nombreph: Buffer.from(Global.Ph.Nombre).toString("base64"),
              };

              axios
                .post(`${Global.Ruta.Url}porteria/enviarmensajeauto`, body)
                .then((res) => {
                  if (resp.data.message === "") {
                    swal({
                      title: "MENSAJE ENVIADO",
                      text: "Desea enviar otro mensaje?",
                      icon: "success",
                      buttons: ["NO", "SI"],
                    }).then((result) => {
                      if (result) {
                        this.setState({
                          password: "",
                          titulo: "",
                          contenido: "",
                          TipoEnvio: 1,
                          showPassword: false,
                          data: true,
                          NombreDesti: "",
                          caso: 0,
                          tituloTabla: "",
                          IdInmuebleSelect: [],
                          checkbox: [true, false, false, false, false, false],
                        });
                      } else {
                        this.setState({
                          redirect_home: true,
                        });
                      }
                    });
                  } else {
                    swal({
                      title: "ERROR!",
                      text: "Error al enviar el mensaje, por favor vuelva a intentarlo.",
                      icon: "error",
                      button: "Aceptar",
                    }).then(() => {
                      this.MensajesAutm(Caso, Mensaje);
                      this.setState({data: true});
                    });
                  }
                })
                .catch((err) => {
                  swal({
                    title: "ERROR!",
                    text: "Por favor vuelva a intentarlo mas tarde.",
                    icon: "error",
                    button: "Aceptar",
                  });
                });
            } else {
              swal({
                title: "ALERTA!",
                text: "Contraseña incorrecta.",
                icon: "error",
                button: "Aceptar",
              }).then(() => {
                this.MensajesAutm(Caso, Mensaje);
                this.setState({data: true});
              });
            }
          })
          .catch((err) => {
            swal({
              title: "ALERTA!",
              text: "61010 - Vaya algo salio mal, por favor vuelva a intentarlo mas tarde.",
              icon: "error",
              button: "Aceptar",
            }).then(() => {
              this.setState({data: true});
            });
          });
      } else {
        this.setState({data: true});
      }
    });
  };

  render() {
    if (this.state.redirect_home === true) {
      return <Redirect to={Global.Ruta.Raiz + "/home-admin"} />;
    }

    if (this.state.redirect_login === 1) {
      return <Redirect to={Global.Ruta.Raiz + "/"} />;
    }

    if (
      this.state.data === true &&
      (Global.GlobalUser.rol === 1 || Global.GlobalUser.rol === 2)
    ) {
      return (
        <div className="container-fluid bg-light">
          {/* INICIO DE LOS POPUPS */}

          {/* LISTAR POPUP PARA SELECCIONAR PROPIETARIOS O RESIDENTES */}

          <Modal
            show={this.state.modalShow}
            fullscreen
            backdrop="static"
            onHide={this.CloseModal}
          >
            <Modal.Header className="pe-5" closeButton></Modal.Header>
            <TablaContactosMsn
              tituloT={this.state.tituloTabla}
              caso={this.state.caso}
              cerrar={this.cerrarModal}
              guardar={this.guardarDataModal}
            />
          </Modal>

          {/* SELECCIONAR GRUPOS DE MENSAJERIA */}

          <Modal
            show={this.state.modalShowG}
            fullscreen
            backdrop="static"
            onHide={this.CloseModalG}
          >
            <Modal.Header className="pe-5" closeButton></Modal.Header>
            <TablaSelectGruposMsn
              id="parent-modal-title"
              tituloT={this.state.tituloTabla}
              cerrar={this.cerrarModelG}
              guardar={this.guardarModalSelectG}
            />
          </Modal>

          {/* EDITAR GRUPOS DE MENSAJERIA */}
          <Modal
            show={this.state.modalShowEditG}
            fullscreen
            backdrop="static"
            onHide={this.CloseModalEditG}
          >
            <Modal.Header className="pe-5" closeButton></Modal.Header>
            <TablaGruposMsn
              id="parent-modal-title"
              tituloT={this.state.tituloTabla}
              cerrar={this.cerrarModelEditG}
            />
          </Modal>

          <Modal
            show={this.state.modalHistoriMsn}
            fullscreen
            backdrop="static"
            onHide={this.CloseModalHstrMsn}
          >
            <Modal.Header className="pe-5" closeButton></Modal.Header>
            <TablaListHmsn
              id="parent-modal-title"
              tituloT="Historial de Mensajeria"
              cerrar={this.cerrarModelHmsn}
            />
          </Modal>

          <Modal
            show={this.state.modalSubApp}
            fullscreen
            backdrop="static"
            onHide={this.ClosemodalSubApp}
          >
            <Modal.Header className="pe-5" closeButton></Modal.Header>
            <TablaListSubApp
              id="parent-modal-title"
              tituloT="Suscriptores con la App GoPH"
              cerrar={this.cerrarModalSubApp}
            />
          </Modal>

          <Modal
            show={this.state.modalSubSapp}
            fullscreen
            backdrop="static"
            onHide={() => this.setState({modalSubSapp: false})}
          >
            <Modal.Header className="pe-4" closeButton></Modal.Header>
            <TablaListSubSapp cerrar={this.cerrarModalSubSinApp} />
          </Modal>

          <Modal
            show={this.state.modalPasswrd}
            centered
            backdrop="static"
            onHide={() => this.setState({modalPasswrd: false})}
          >
            <Modal.Header closeButton>Confirmar Contraseña</Modal.Header>
            <Modal.Body className="d-flex justify-content-center">
              <TextField
                className="psswrdMsn"
                label="Contraseña mensajeria"
                variant="outlined"
                margin="dense"
                value={this.state.password}
                type={this.state.showPassword ? "text" : "password"}
                onChange={this.handleChange("password")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.EstadoVerClave}
                      >
                        {this.state.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-primary text-white text-uppercase fw-bold"
                onClick={this.EnviarMsn}
              >
                enviar
              </button>
            </Modal.Footer>
          </Modal>

          <div className="row px-2 pt-3 gy-3">
            {/* FINAL DE LOS POPUPS */}

            <div className="col-12">
              <HeadTitle
                classNameOwn="row align-items-center px-3"
                titulo="Mensajería"
                subTitulo="Módulo para enviar notificaciones a propietarios o residentes."
                indicadores={[]}
                actionButton={
                  <div className="buttons-container">
                    <div className="col col-md-auto d-grid  ">
                      <button
                        className="btn btn-primary shadow"
                        variant="extended"
                        size="small"
                        onClick={() => {
                          this.setState({modalHistoriMsn: true});
                        }}
                      >
                        <span className="">Historial Mensajes</span>
                      </button>
                    </div>
                    <div className="col col-md-auto d-grid">
                      <button
                        className="btn btn-success shadow"
                        variant="extended"
                        size="small"
                        onClick={() => {
                          this.setState({modalSubApp: true});
                        }}
                      >
                        <span className="">Suscriptores con App</span>
                      </button>
                    </div>
                    <div className="col col-md-auto d-grid">
                      <button
                        className="btn btn-warning shadow"
                        variant="extended"
                        size="small"
                        onClick={() => {
                          this.setState({modalSubSapp: true});
                        }}
                      >
                        <span className="">Suscriptores sin App</span>
                      </button>
                    </div>
                  </div>
                }
              />
            </div>

            <div className="col-12">
              {/* opciones de remitentes */}
              <div className='card shadow' style={{overflowX: 'hidden', overflowY: 'auto'}}>
                <div className="card-body">
                  <div className="row pt-3">
                  <FormControl
                        component="fieldset"
                        className="col-12"
                      >
                        <div className="row justify-content-center">
                        <div className="col-12">
                          <div className="row row-cols-2 justify-content-center">
                            <div className="col-auto">
                              <p className="m-0 fw-light text-muted me-2">
                                Seleccione destinatario...
                              </p>
                            </div>
                            <div className="col-auto">
                              <p className="m-0 cover-marqss fw-light text-muted text-capitalize">
                                <span className={`${textFloow.titulo}`}>
                                  {this.state.NombreDesti}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                          <div className="col-auto">
                            <RadioGroup
                              row
                              className="row row-cols-1 row-cols-lg-auto justify-content-center"
                              name="radio-buttons-group"
                            >
                              <FormControlLabel
                                className="col-auto mx-1"
                                value="todos"
                                control={
                                  <Checkbox
                                    checked={this.state.checkbox[0]}
                                    onChange={(event) => {
                                      this.checked(event, 0);
                                    }}
                                    color="default"
                                  />
                                }
                                label="Todos"
                              />
                
                              <FormControlLabel
                                className="col-auto mx-1"
                                value="solo porpietarios"
                                control={
                                  <Checkbox
                                    color="default"
                                    checked={this.state.checkbox[1]}
                                    onChange={(event) => {
                                      this.checked(event, 1);
                                    }}
                                  />
                                }
                                label="Solo propietarios"
                              />
                
                              <FormControlLabel
                                className="col-auto mx-1"
                                value="solo residnetes"
                                control={
                                  <Checkbox
                                    color="default"
                                    checked={this.state.checkbox[2]}
                                    onChange={(event) => {
                                      this.checked(event, 2);
                                    }}
                                  />
                                }
                                label="Solo residentes"
                              />
                
                              <FormControlLabel
                                className="col-auto mx-1"
                                value="a un propietarios"
                                control={
                                  <Checkbox
                                    color="default"
                                    checked={this.state.checkbox[3]}
                                    onChange={(event) => {
                                      this.checked(event, 3);
                                    }}
                                  />
                                }
                                label="A un propietario"
                              />
                
                              <FormControlLabel
                                className="col-auto mx-1"
                                value="a un residnetes"
                                control={
                                  <Checkbox
                                    color="default"
                                    checked={this.state.checkbox[4]}
                                    onChange={(event) => {
                                      this.checked(event, 4);
                                    }}
                                  />
                                }
                                label="A un residente"
                              />
                
                              <FormControlLabel
                                className="col-auto mx-1"
                                value="a un grupo"
                                control={
                                  <Checkbox
                                    color="default"
                                    checked={this.state.checkbox[5]}
                                    onChange={(event) => {
                                      this.checked(event, 5);
                                    }}
                                  />
                                }
                                label="A un grupo"
                              />
                            </RadioGroup>
                          </div>
                
                          <div className="col-auto">
                            <button
                              className="btn btn-primary text-capitalize fw-bold p-2 shadow btn-sm"
                              onClick={() => {
                                this.setState({
                                  modalShowEditG: true,
                                  tituloTabla: "Grupos",
                                });
                              }}
                            >
                              <span className="mx-1">Editar Grupos</span>
                            </button>
                          </div>
                        </div>
                      </FormControl>
                  </div>
                  {/* asunto, cuerpo de mensaje y boton de envio */}
                  <div className="row mt-4 mb-1 gy-2">
                    <div className="col-12">
                    <TextField
                        id="nombreMsn"
                        value={this.state.titulo}
                        label="Titulo del Mensaje"
                        variant="outlined"
                        fullWidth
                        onChange={this.handleChange("titulo")}
                      />
                    </div>
                    <div className="col-12">
                      <TextField
                        id="mensaje"
                        value={this.state.contenido}
                        multiline
                        rows={5}
                        fullWidth
                        label="Cuerpo del Mensaje"
                        helperText={`${this.state.contenido.length} de 1000 caracteres`}
                        variant="outlined"
                        margin="dense"
                        onChange={this.handleChange("contenido")}
                        inputProps={{maxLength: 1000}}
                      />
                    </div>
                  </div>
                  <div className="row py-3 justify-content-center">
                    <div className="col-auto">
                      <button
                        className="btn btn-primary text-capitalize fw-bold px-4 shadow"
                        onClick={() => this.setState({modalPasswrd: true})}
                      >
                        enviar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (this.state.data === true && Global.GlobalUser.rol === 4) {
      return (
        <div className="container-fluid pb-5 bg-light">
          {/* LISTAR POPUP PARA SELECCIONAR PROPIETARIOS O RESIDENTES */}

          <Modal
            show={this.state.modalShow}
            fullscreen
            backdrop="static"
            onHide={this.CloseModal}
          >
            <Modal.Header className="pe-5" closeButton></Modal.Header>
            <TablaContactosMsn
              tituloT={this.state.tituloTabla}
              caso={this.state.caso}
              cerrar={this.cerrarModal}
              guardar={this.guardarDataModal}
            />
          </Modal>

          <Modal
            show={this.state.modalRespAut}
            className="overflow-auto"
            centered
          >
            <div className="container-fluid d-flex align-items-center min-vh-100 py-3">
              <Modal.Header className="pe-5" closeButton></Modal.Header>
              <ListaRespAut
                onClose={() => this.setState({modalRespAut: false})}
                enviar={() => this.setState({modalPasswrd: true})}
              />
            </div>
          </Modal>

          <Modal
            show={this.state.modalHistoriPort}
            onHide={() => this.setState({modalHistoriPort: false})}
            fullscreen
            centered
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <TablaHistorialPort
                cerrar={() => this.setState({modalHistoriPort: false})}
              />
            </Modal.Body>
          </Modal>

          <Modal
            show={this.state.modalPasswrd}
            centered
            backdrop="static"
            onHide={() => this.setState({modalPasswrd: false})}
          >
            <Modal.Header closeButton>Confirmar Contraseña</Modal.Header>
            <Modal.Body className="d-flex justify-content-center">
              <TextField
                className="psswrdMsn"
                label="Contraseña mensajeria"
                variant="outlined"
                margin="dense"
                value={this.state.password}
                type={this.state.showPassword ? "text" : "password"}
                onChange={this.handleChange("password")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.EstadoVerClave}
                      >
                        {this.state.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-primary text-white text-uppercase fw-bold"
                onClick={this.EnviarMsnPorteria}
              >
                enviar
              </button>
            </Modal.Footer>
          </Modal>

          {/* FINAL DE LOS POPUPS */}

          <div className="row px-2">
            <div className="card col my-4 bg-white shadow">
              <div className="row justify-content-center  justify-content-lg-end p-3 px-4">
                <div className="col-auto ">
                  <div className="row row-cols-3 gx-1">
                    <div className="col col-md-auto">
                      <button
                        className="btn btn-secondary"
                        variant="extended"
                        size="small"
                        onClick={() => {
                          this.setState({modalHistoriPort: true});
                        }}
                      >
                        <span className="">Historial Mensajes</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="row row-cols-3" style={{height: "0.5rem"}}>
                    <div
                      className="col"
                      style={{backgroundColor: "#d92534"}}
                    ></div>
                    <div
                      className="col"
                      style={{backgroundColor: "#8c8c7d"}}
                    ></div>
                    <div
                      className="col"
                      style={{backgroundColor: "#f2a516"}}
                    ></div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <FormControl component="fieldset" className="row w-100 py-2">
                    <FormLabel
                      component="legend"
                      className="col-12 my-2 text-center fs-3"
                    >
                      Enviar mensaje a...
                    </FormLabel>
                    <div className="col-auto py-1">
                      <RadioGroup
                        row
                        className=" row-cols-2 justify-content-center"
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          className="col-auto mx-1"
                          value="solo residentes"
                          control={
                            <Checkbox
                              color="default"
                              checked={this.state.checkbox[2]}
                              onChange={(event) => {
                                this.checked(event, 2);
                              }}
                            />
                          }
                          label="Todos los residentes"
                        />

                        <FormControlLabel
                          className="col-auto mx-1"
                          value="a un residentes"
                          control={
                            <Checkbox
                              color="default"
                              checked={this.state.checkbox[4]}
                              onChange={(event) => {
                                this.checked(event, 4);
                              }}
                            />
                          }
                          label="A un residente"
                        />
                      </RadioGroup>
                    </div>
                    <div className="col-12 py-2">
                      <div className="row justify-content-center">
                        <Card
                          className="col-auto px-3 py-1 m-0"
                          style={{backgroundColor: "SteelBlue"}}
                        >
                          <p className={textFloow.titulo + " text-white"}>
                            {this.state.NombreDesti.toUpperCase()}
                          </p>
                        </Card>
                      </div>
                    </div>
                  </FormControl>
                </div>
                <div className="col-12 d-flex flex-column px-3">
                  <TextField
                    id="nombreMsn"
                    value={this.state.titulo}
                    label="Titulo del Mensaje"
                    variant="outlined"
                    margin="dense"
                    onChange={this.handleChange("titulo")}
                  />
                  {/*<div className="row justify-content-center py-3">
                                        <button className="btn btn-primary w-25 font-weight-bold" onClick={() => this.setState({ modalRespAut: true })}>RESPUESTAS AUTOMATICAS</button>
                                    </div>*/}
                  <TextField
                    id="mensaje"
                    value={this.state.contenido}
                    multiline
                    rows={10}
                    label={this.state.msnAutomatico}
                    helperText={`${this.state.contenido.length} de 1000 caracteres`}
                    variant="outlined"
                    margin="dense"
                    onChange={this.handleChange("contenido")}
                    inputProps={{maxLength: 1000}}
                  />
                  {/* contraseña */}
                </div>
              </div>
              <div className="row py-3 justify-content-center">
                <div className="col-auto">
                  <button
                    className="btn btn-primary text-white text-uppercase fw-bold"
                    onClick={() => this.setState({modalPasswrd: true})}
                  >
                    enviar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="d-flex justify-content-center text-info donita">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
}

export default SistemaMsn;
